

.splash {
    display: none;
}

[ng-cloak].splash {
    display: block !important;
}

[ng-cloak].splashSpinner {
    display: block !important;
}

.splash {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.60;
    background-color: #000;
    z-index: 999999;
}

.splash .carregando {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -62px 0 0 -116px;
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    width: 130px;
    height: 80px;
}
.splash img {
}
.splash h2 {
    text-align: center;
    font-size: 1.6em;
    color: #000;
}


.overlayLoading {
    background: #000;
    float: left;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 999;
    opacity: 0.65;
    -moz-opacity: 0.65;
    filter: alpha(opacity=65);
}

.layerLoading {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -62px 0 0 -60px;
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    z-index: 999999;
}

.layerLoading div {
    text-align: center;
    font-size: 1.3em;
    font-weight: bold;
    color: #000;
}